<template>
  <div class="gradientLine"></div>
</template>

<script>
</script>

<style lang="scss" scoped>
.gradientLine {
  height: 0.35rem;
  width: 100%;
  //background: linear-gradient(to right, #ff2d55,#5856d6,#34aadc,#007aff,#5ac8fa,#4cd964);
  animation: lineChange 0.5s linear infinite;
}
@keyframes lineChange {
  0% {
    background: linear-gradient(to right,#4cd964, #ff2d55,#5856d6,#34aadc,#007aff,#5ac8fa);
  }
  20% {
    background: linear-gradient(to right,#5ac8fa,#4cd964, #ff2d55,#5856d6,#34aadc,#007aff);
  }
  40% {
    background: linear-gradient(to right,#007aff,#5ac8fa,#4cd964, #ff2d55,#5856d6,#34aadc);
  }
  60% {
    background: linear-gradient(to right,#34aadc,#007aff,#5ac8fa,#4cd964, #ff2d55,#5856d6);
  }
  80% {
    background: linear-gradient(to right,#34aadc,#007aff,#5ac8fa,#4cd964, #ff2d55,#5856d6);
  }
  80% {
    background: linear-gradient(to right,#5856d6,#34aadc,#007aff,#5ac8fa,#4cd964, #ff2d55);
  }
  100% {
    background: linear-gradient(to right, #ff2d55,#5856d6,#34aadc,#007aff,#5ac8fa,#4cd964);
  }
  120% {
    background: linear-gradient(to right,#4cd964,#ff2d55,#5856d6,#34aadc,#007aff,#5ac8fa);
  }
}
</style>
