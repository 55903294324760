import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

import {computed,ref} from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    font: {}
  },
  setup(__props: any) {

let props = __props
let fontSplitList = computed(()=> {
  if(props.font) {
    return props.font.toString().split('')
  } else {
    return []
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "colorFontBox",
    style: _normalizeStyle({'--spanLength': _unref(fontSplitList)})
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(fontSplitList), (item, index) => {
      return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(item), 1))
    }), 128))
  ], 4))
}
}

})