<template>
  <div id="layoutBox">
    <div class="headerBox">
<!--      <div class="gradientLine"></div>-->
      <GradientLine></GradientLine>
      <div class="headerContent">
        <div class="titleBox">
          <ColorFont font="老男孩拯救计划"></ColorFont>
        </div>
        <div class="itemBox" style="height: 100%">
          <menuComp/>
        </div>

        <div class="searchBox">
          <i class="el-icon-search"></i>
        </div>
      </div>
    </div>
    <div id="contentBox">
      <router-view/>
    </div>
    <div style="width: 100%;text-align: center;color: #bbb;position: fixed;bottom: 5px">

      <span class="hoverColor" @click="toOther">豫ICP备2024049303号-1</span>
    </div>
  </div>
</template>

<script>
import menuComp from "./menuComp";
import ColorFont from '@/components/VisualComponent/ColorFont/index.vue'
import GradientLine from '@/components/VisualComponent/GradientLine/index.vue'
import {defineComponent} from "vue";
export default defineComponent( {
  name: "index",
  components: {menuComp,ColorFont, GradientLine},
  data() {
    return {
      color: 'blue'
    }
  },
  methods: {
    toOther() {
      // window.location.href = 'https://beian.miit.gov.cn'
      window.open('https://beian.miit.gov.cn')
    }
  }
})
</script>

<style lang="scss" scoped>
.hoverColor:hover {
  color: #007aff;
  cursor: pointer;
  border-bottom: 1px solid #007aff;
}
#layoutBox{
  width: 100%;
  background-color: #E9EAED;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  min-height: 100vh;
}
.headerBox {
  width: 100%;
  background-color: #1C2327;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  //.gradientLine {
  //  height: 0.35rem;
  //  width: 100%;
  //  background: linear-gradient(to right, #ff2d55,#5856d6,#34aadc,#007aff,#5ac8fa,#4cd964);
  //}
  .headerContent {
    width: 70%;
    height: 3.5rem;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: flex-start;
    .titleBox{
      font-size: 1.3rem;
    }
    .searchBox{
      font-size: 1.3rem;
    }
  }
}

#contentBox {
  width: 70vw;
}
</style>
