import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "menuBox" }
const _hoisted_2 = ["onClick"]

import {ref} from "vue";
import {useRouter} from "vue-router";
type menu = {name:string, code:string, path:string}

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

let router = useRouter()
let menuList = ref<menu[]>([
    {name: '首页', code: 'home', path: ''},
    {name: '生活记录', code: '', path: ''},
    // {name: '网站博客制作', code: '', path: ''},
    {name: '学习园地', code: 'study', path: ''},
    // {name: '小游戏', code: 'snake', path: ''},
    {name: '关于我', code: '', path: ''},
])
function goPage(name:string) {
  router.push({name: name})
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuList), (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "menuItem",
        key: item.name,
        onClick: ($event: any) => (goPage(item.code))
      }, _toDisplayString(item.name), 9, _hoisted_2))
    }), 128))
  ]))
}
}

})